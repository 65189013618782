import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "job/seekers"

export const jobSeekerApi = createApi({
  reducerPath: "job/seekers",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["JobSeeker"],
  endpoints: (builder) => ({
    getJobSeeker: builder.query({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      transformResponse: (response) => response.results,
    }),
    getJobSeekerAdmin: builder.query({
      query: (params) => ({
        url: `admin/jobs/seekers/admin`,
        params,
        headers: authHeader(),
        method: "get",
      }),
      transformResponse: (response) => response,
    }),
    getJobSeekerEmployer: builder.query({
      query: (params) => ({
        url: `employer/jobs/seekers`,
        params,
        headers: authHeader(),
        method: "get",
      }),
      transformResponse: (response) => response,
    }),
  }),
})

export const { useGetJobSeekerAdminQuery, useGetJobSeekerQuery, useGetJobSeekerEmployerQuery } =
  jobSeekerApi

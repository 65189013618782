/* eslint-disable no-unused-vars */
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Briefcase, LogIn, Menu, User } from "react-feather"
import { Link, useNavigate } from "react-router-dom"

import logoLong from "@src/assets/images/logo/logotransparent1.png"
import logoLongNew from "@src/assets/images/logo/logo-new.png"
import { useTranslation } from "react-i18next"
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from "reactstrap"
import IntlDropdown from "../../@core/layouts/components/navbar/IntlDropdown"
import { loggedInfo } from "../../helpers/checkAuth"
import { logout } from "../../helpers/localstorage"
import { SocketContext } from "@src/utility/context/Socket"
import "@src/assets/css/v2-navbar.css"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"

const NavigationBar = forwardRef(({ navigations, page, isNew = false, onCurrentHeight }, ref) => {
  const socket = useContext(SocketContext)
  const { token, role } = loggedInfo()

  const [isOpen, setOpen] = useState(false)
  const [isLogged, setLogged] = useState(false)
  const [currentRole, setCurrentRole] = useState(false)
  const [enableShadow, setEnableShadow] = useState(false)

  const navigate = useNavigate()

  const handleLogout = useCallback(() => {
    logout()
    socket.disconnect()
    navigate("/login", { replace: true })
  }, [navigate, socket])

  const toggle = () => setOpen(!isOpen)

  const handleScroll = () => {
    const scrollPosition = Math.round(window.scrollY) // => scroll position
    if (scrollPosition > 128) {
      setEnableShadow(true)
    } else {
      setEnableShadow(false)
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setLogged(token !== null)
    setCurrentRole(role)
  }, [token, role])

  if (isNew) {
    return (
      <NewHeader
        ref={ref}
        icon={logoLongNew}
        enableShadow={enableShadow}
        handleLogout={handleLogout}
        toggle={toggle}
        isLogged={isLogged}
        isOpen={isOpen}
        role={currentRole}
        navigations={navigations}
        page={page}
      />
    )
  }

  return (
    <OldHeader
      icon={logoLong}
      enableShadow={enableShadow}
      handleLogout={handleLogout}
      toggle={toggle}
      isLogged={isLogged}
      isOpen={isOpen}
      currentRole={currentRole}
      navigations={navigations}
      page={page}
    />
  )
})

const NewHeader = forwardRef(
  (
    { icon, isLogged, enableShadow, role, handleLogout, navigations, page, toggle, isOpen },
    ref
  ) => {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const isMobile = width <= 768

    const homeNav = useMemo(() => {
      return page === "seeker" ? "/seeker" : "/employer"
    }, [page])

    return (
      <Navbar
        ref={ref}
        expand="md"
        fixed="top"
        className={`header-wrapper-v2 ${enableShadow ? "shadow" : ""} ${
          isMobile ? "" : "custom-container"
        }`}
        light={true}
        container={isMobile ? "fluid" : false}
      >
        <NavbarBrand href={homeNav}>
          <img
            alt="logo"
            src={icon}
            style={{
              height: 48,
            }}
          />
        </NavbarBrand>
        {isMobile && (
          <Nav>
            <NavItem className="mr-1">
              <IntlDropdown />
            </NavItem>
            <NavbarToggler onClick={toggle} />
          </Nav>
        )}
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {navigations.length > 0
              ? navigations
                  .filter((e) => !e.isRight)
                  .filter((e) => {
                    if (e.isLogged) {
                      if (role === "superadmin" && e.navLink === "/dashboard") {
                        return true
                      } else if (role === "seeker" && e.navLink.includes(`${role}/dashboard`)) {
                        return true
                      } else if (role === "employer" && e.navLink.includes(`${role}/dashboard`)) {
                        return true
                      } else {
                        return false
                      }
                    } else {
                      return true
                    }
                  })
                  .map((item) => {
                    return (
                      <NavItem key={item.id} className="nav-mx-1 list-menu-name">
                        <NavLink tag={Link} to={item.navLink} className="link-nav">
                          {t(item.title)}
                        </NavLink>
                      </NavItem>
                    )
                  })
              : null}
          </Nav>

          <Nav className="ms-auto" navbar>
            {!isMobile && !isLogged && (
              <NavItem className="me-1">
                <Link to={"/login"} className="btn rounded-pill btn-header-purple-outline">
                  {t("Login")}
                </Link>
              </NavItem>
            )}
            {!isMobile && !isLogged && (
              <NavItem className="me-1">
                <Link to={"/register-for"} className="btn btn-header-purple rounded-pill">
                  {t("Sign up")}
                </Link>
              </NavItem>
            )}

            {!isLogged && isMobile && (
              <NavItem className="py-1 row justify-content-center align-items-center px-1">
                <div className="col px-0" style={{ marginRight: 6 }}>
                  <Link to={"/login"} className="btn rounded-pill btn-header-purple-outline w-100">
                    {t("Login")}
                  </Link>
                </div>
                <div className="col px-0" style={{ marginLeft: 6 }}>
                  <Link to={"/register-for"} className="btn btn-header-purple rounded-pill w-100">
                    {t("Sign up")}
                  </Link>
                </div>
              </NavItem>
            )}

            {isLogged && (
              <NavItem className="me-1">
                <button
                  onClick={handleLogout}
                  className={`btn rounded-pill btn-header-purple-outline ${
                    isMobile ? "w-100 my-1" : ""
                  }`}
                >
                  {t("Logout")}
                </button>
              </NavItem>
            )}

            {!isMobile && (
              <NavItem className="">
                <IntlDropdown />
              </NavItem>
            )}
          </Nav>

          <div className="d-none align-item-center">
            <div className="extra-cell">
              <div className="header-nav-btn-section">
                {page === "employer" ? (
                  <div className="twm-nav-btn-right">
                    <Link to={"/seeker"} className="btn btn-header-purple rounded-pill">
                      {t("Job Seeker")}
                    </Link>
                  </div>
                ) : (
                  <div className="twm-nav-btn-right">
                    <Link to={"/employer"} className="btn btn-header-purple rounded-pill">
                      {t("Employer/Postjob")}
                    </Link>
                  </div>
                )}

                {/* register & login */}
                <div className="twm-nav-btn-left">
                  {isLogged ? (
                    <a href="#" className="twm-nav-sign-up rounded-pill" onClick={handleLogout}>
                      <LogIn size={18} />
                      {t("Logout")}
                    </a>
                  ) : (
                    <Link to={"/login"} className="btn rounded-pill btn-header-purple-outline">
                      {t("Login")}
                    </Link>
                  )}
                </div>
                {!isLogged && (
                  <div className="twm-nav-btn-right">
                    <Link to={"/register-for"} className="btn btn-header-purple rounded-pill">
                      {t("Sign up")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="extra-cell">
              <IntlDropdown />
            </div>
          </div>
        </Collapse>
      </Navbar>
    )
  }
)

const OldHeader = ({
  icon,
  isLogged,
  role,
  handleLogout,
  navigations,
  page,
  toggle,
  isOpen,
  width,
}) => {
  const { t } = useTranslation()
  return (
    <header className="site-header header-style-3 mobile-sider-drawer-menu">
      <Navbar expand="md" fixed="top" className="shadow" color={"primary"}>
        <div className="main-bar sticky-header main-bar-wraper">
          <NavbarBrand tag={Link} to="/" className="logo-header">
            <div className="logo-header-inner logo-header-one">
              <img
                src={icon}
                alt="Keihin"
                className={`img-fluid`}
                style={{
                  maxWidth:
                    width <= 1280 && width >= 768 ? "100%" : role === "seeker" ? "100%" : "110%",
                }}
              />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle}>
            <Menu />
          </NavbarToggler>
          <Collapse
            isOpen={isOpen}
            navbar
            className={`justify-content-center ${width <= 768 && "mt-5"}`}
          >
            <Nav className="nav" navbar>
              {navigations.length > 0
                ? navigations
                    .filter((e) => !e.isRight)
                    .map((item) => {
                      if (isLogged === false && item.navLink === "/seeker/dashboard") return false
                      if (
                        role !== "seeker" &&
                        role !== "employer" &&
                        String(item.navLink).includes("dashboard")
                      ) {
                        return false
                      }
                      if (String(item.navLink).includes("seeker/dashboard") && role !== "seeker") {
                        return false
                      }
                      if (
                        String(item.navLink).includes("employer/dashboard") &&
                        role !== "employer"
                      ) {
                        return false
                      }
                      return (
                        <NavItem key={item.id} className="m-r20">
                          <NavLink tag={Link} to={item.navLink} className="text-white">
                            {t(item.title)}
                          </NavLink>
                        </NavItem>
                      )
                    })
                : null}
            </Nav>
            <Nav className="nav" navbar>
              <div className="extra-nav header-2-nav">
                {/* {navigations.length > 0 ? navigations.filter(e => e.isRight).map(item => {
                if (String(item.navLink).includes('logout') && isLogged) {
                  return <a key={item.id} href="#" type="button" onClick={handleLogout} className={'text-primary'}>Logout</a>
                }
                const navitem = (bg, textColor) => (
                  <div key={item.id} className={`extra-cell`}>
                    <div className="header-nav-btn-section">
                      <div tag={Link} to={item.navLink} className={textColor}>{t(item.title)}</div>
                    </div>
                  </div>
                )

                let show = false
                if (item.isLogged === isLogged) show = true
                if (item.isLogged === null) show = true
                if (String(item.navLink).includes('dashboard') && isLogged && page !== currentRole) show = false
                return show ? navitem(item.bg, item.textColor) : null
              }) : null} */}
                <div className="extra-cell">
                  <div className="header-nav-btn-section">
                    {page === "employer" ? (
                      <div className="twm-nav-btn-right">
                        <Link to={"/seeker"} className="twm-nav-post-a-job">
                          <Briefcase size={18} /> {t("Job Seeker")}
                        </Link>
                      </div>
                    ) : (
                      <div className="twm-nav-btn-right">
                        <Link to={"/employer"} className="twm-nav-post-a-job">
                          <Briefcase size={18} /> {t("Employer/Postjob")}
                        </Link>
                      </div>
                    )}
                    <div className="twm-nav-btn-left">
                      {isLogged ? (
                        <a href="#" className="twm-nav-sign-up" onClick={handleLogout}>
                          <LogIn size={18} />
                          {t("Logout")}
                        </a>
                      ) : (
                        <Link to={"/login"} className="twm-nav-sign-up">
                          <LogIn size={18} /> {t("Login")}
                        </Link>
                      )}
                    </div>
                    {!isLogged && (
                      <div className="twm-nav-btn-right">
                        <Link to={"/register-for"} className="twm-nav-post-a-job">
                          <User size={18} /> {t("Sign up")}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="extra-cell">
                  <IntlDropdown />
                </div>
              </div>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default NavigationBar

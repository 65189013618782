import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig, authHeader } from "../../helpers/http"

const defaultPath = "admin/companies"

export const adminCompanyApi = createApi({
  reducerPath: "company",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["JobPost"],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: (params) => ({
        url: `${defaultPath}`,
        params,
        method: "get",
        headers: authHeader(),
      }),
      providesTags: ["GetCompanies"],
      transformResponse: (response) => response,
    }),
    getCompanyByUserId: builder.mutation({
      query: (id) => ({
        url: `${defaultPath}/${id}`,
        method: "get",
        headers: authHeader(),
      }),
      providesTags: ["GetCompaniesByUserId"],
      transformResponse: (response) => response.results,
    }),
  }),
})

export const { useGetCompaniesQuery, useGetCompanyByUserIdMutation, useGetMyCompanyMutation } =
  adminCompanyApi

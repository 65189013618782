import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import photo from "@src/assets/images/employer/candidates/img.png"
import Location from "@src/assets/icons/location-dot.svg"
import Tower from "@src/assets/icons/tower.svg"

const CardCandidate = ({ image, item, onClick = () => {} }) => {
  const { width: widthScreen } = useWindowDimensions()
  const isMobile = widthScreen <= 768

  return (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #E9ECEF",
      }}
      className={`bg-white radius-md ${isMobile ? "p-1" : "p-1"}`}
    >
      <div style={{ position: "relative" }} className="">
        <div
          style={{
            fontSize: 8,
            position: "absolute",
            bottom: 8,
            borderRadius: 4,
            left: 8,
            color: "#fff",
            background: "#92388E",
            padding: "2px 4px 2px 4px",
          }}
          className=""
        >
          {item?.qualification || "-"}
        </div>
        <img
          style={{
            borderRadius: 8,
            height: "200px",
            width: "100%",
            objectFit: "cover",
          }}
          src={image ? image : photo}
          alt="Photo Candidates"
        />
      </div>
      <div className="flex item-start flex-col gap-y-2 mt-1">
        <h5 style={{ fontSize: 14, fontWeight: "bold" }} className="">{`${item?.firstName || "-"} ${
          item?.lastName || "-"
        } `}</h5>
        <div>
          <div className=" flex gap-x-2">
            <img src={Tower} alt="tower" />
            <div style={{ fontSize: 10 }} className="text-gray-400">
              {item?.qualification2 || "-"}
            </div>
          </div>
          <p className="flex gap-x-2 ">
            <img src={Location} alt="location" width={12} />
            <div style={{ fontSize: 10 }} className={`text-gray-400`}>
              {item?.city || "-"}
            </div>
          </p>
        </div>
      </div>
    </div>
  )
}

export default CardCandidate
